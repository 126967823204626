import styled from "@emotion/styled"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Seo } from "gatsby-theme-grape-blog/src/components/seo"
import { Button } from "../components/basic"
import { Brand } from "../components/brand"
import Layout, { Header } from "../components/layout"
import { theme } from "../utils/theme"
import { rhythm } from "../utils/typography"
import { FaPatreon, FaYoutube } from "react-icons/fa"
import { GiLetterBomb } from "react-icons/gi"
import { BsGlobe2 } from "react-icons/bs"
import LogoSVG from "../../content/assets/logo.inline.svg"
import LeavesSVG from "../../content/assets/leaves.inline.svg"

const Logo = styled(LogoSVG)`
  fill: #fff;
  width: 10rem;
`

const Leaves = styled(LeavesSVG)`
  fill: #fff;
  position: fixed;
  z-index: 20;
`

const LeavesLeft = styled(Leaves)`
  width: 6rem;
  left: -1.25rem;
  transform: rotate(125deg);
  margin-top: 9rem;
`

const LeavesRight = styled(Leaves)`
  width: 4rem;
  right: 0;
  transform: rotate(340deg);
  margin-top: -5.5rem;
`

const BottomImage = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem 2rem 1rem 2rem;
  text-align: center;
`

const ClickmeLayout = styled(Layout)`
  padding-top: 1.5rem;
  background-color: #219ebc;

  main {
    padding: 0 2rem 2rem 2rem;
  }

  header {
    background-color: #219ebc;
  }
`

const StyledBrand = styled(Brand)`
  h1 {
    font-size: 1.1rem;
    color: #fff;
  }

  padding: 0 2rem;
`

const LinksList = styled.ul`
  list-style-type: none;
  position: relative;
  max-width: 40rem;
  margin: 0 auto ${rhythm(2)} auto;
  z-index: 10;

  li {
    width: 100%;
    margin-bottom: ${rhythm(1)};
  }

  a:hover {
    span {
      background-image: none;
    }

    @media (hover: hover) {
      opacity: 0.7;
    }
  }
`

const ClickmeButton = styled(Button)`
  flex-direction: row-reverse;
  border: ${({ border = 0 }) => border};
  color: ${({ textColor }) => textColor};
  background-color: ${({ background }) => background};
  border-radius: 0.5rem;

  span {
    flex: 1;
    text-align: center;
    margin-right: 3rem;
    font-size: 1.1rem;
    font-weight: bold;
  }

  > svg {
    font-size: 2rem;
    width: 2.75rem;
    min-width: 2.75rem;
    height: 2.75rem;
    padding: 0.4rem;
    border-radius: 0.5rem;
    color: #fff;
    background: ${({ color }) => color};
  }
`

const FeaturedButton = styled(ClickmeButton)`
  padding-top: 1rem;
  padding-bottom: 1rem;

  div {
    font-size: 1.5rem;
  }

  small {
    font-size: 1.05rem;
    display: block;
    margin-top: 0.4rem;
    line-height: 1.05rem;
  }
`

const LinksPage = () => {
  return (
    <ClickmeLayout footer={() => {}}>
      <Seo meta={[{ name: "robots", content: "noindex" }]} />
      <Header>
        <StyledBrand logoComponent={Logo} />
      </Header>
      <main>
        <LeavesRight />
        <LeavesLeft />
        <BottomImage>
          <StaticImage
            src="../../content/assets/clickme-footer.png"
            placeholder="tracedSVG"
            alt="Footer image"
          />
        </BottomImage>
        <LinksList>
          <li>
            <FeaturedButton
              to="https://www.patreon.com/evidencestrong"
              icon={FaPatreon}
              color="#c41206"
              textColor={theme.colors.text.body}
              background="#ffba0a"
              target="_blank"
            >
              <div>Patreon</div>
              <small>Community</small>
            </FeaturedButton>
          </li>
          <li>
            <ClickmeButton
              to="https://mailchi.mp/evidencestrong/newsletter-sign-up"
              icon={GiLetterBomb}
              color="#023047"
              textColor={theme.colors.text.body}
              background="#ffffff"
              target="_blank"
            >
              Newsletter
            </ClickmeButton>
          </li>
          <li>
            <ClickmeButton
              to="https://www.youtube.com/channel/UCCpnzFCUlyxJtd844nJen6w"
              icon={FaYoutube}
              color="#ff0000"
              textColor={theme.colors.text.body}
              background="#ffba0a"
              target="_blank"
            >
              YouTube
            </ClickmeButton>
          </li>
          <li>
            <ClickmeButton
              to="https://evidencestrong.com"
              icon={BsGlobe2}
              color={theme.colors.text.body}
              textColor={theme.colors.text.body}
              background="#ffffff"
              target="_blank"
            >
              Website
            </ClickmeButton>
          </li>
        </LinksList>
      </main>
    </ClickmeLayout>
  )
}

export default LinksPage
